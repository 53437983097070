<template>
  <div class="myWallet">
    <div class="header flex-center-between">
      <div class="back flex-center-center" @click="$router.go(-1)">
        <svg-icon icon-class="headArrow" />
      </div>
      <div class="title">我的游戏币</div>
      <div class="right-btn" @click="$router.push('/flowDetails')">账单</div>
    </div>
    <div class="main">
      <div class="walletBox">
        <div class="titleBox">
          <div class="goldIcon"></div>
          <span>余额</span>
        </div>
        <div class="amountBox">
          {{ $store.getters.userInfo.amount }}
          <span>游戏币</span>
        </div>
      </div>
      <div class="subTitle">购买游戏币</div>
      <div class="subTip">本APP有稳定的广告收益，安全无毒，可放心使用</div>
      <div class="goldCoinMain">
        <div
          class="goldCoinBox"
          v-for="(item, index) in amountList"
          :key="item.id"
          :class="selectIndex === index ? 'goldCoinBoxActive' : ''"
          @click="selectAmount(index)"
        >
          <div class="rmb">
            <span>¥</span>
            {{ item.money ? item.money / 100 : 0 }}
          </div>
          <div class="goldCoin flex-center-center">
            <div class="goldCoinIcon"></div>
            {{ item.amount || 0 }}
          </div>
          <div class="gift" v-if="item.couponDesc">{{ item.couponDesc }}</div>
        </div>
      </div>
      <div class="nodata" v-if="!amountList.length">{{ dataText }}</div>
      <div class="subTitle">常见问题</div>
      <div class="subTip1">1.如多次支付失败,请尝试其他方式支付</div>
      <div class="subTip">
        2.支付成功后一般10分钟内到账,超过30分钟未到账请
        <span @click="$router.push('/kefu')">联系客服</span>
      </div>
      <div class="sizeBox"></div>
      <div class="openNow" @click="showPay(true)">立即支付</div>
    </div>
    <RechargeBox ref="rechargeBox" :isRecharge="isRecharge" @closePay="showPay" />
  </div>
</template>
<script>
import RechargeBox from '@/components/RechargeBox';
import { queryCurrencys } from '@/api/mine';
import { Toast } from 'vant';

export default {
  name: 'MyWallet',
  components: {
    RechargeBox,
  },
  data() {
    return {
      selectIndex: 0, // 选中下标
      amountList: [], // 金额列表
      isRecharge: false, // 充值界面
      graphicsId: '',
      graphicsTitle: '',
      dataText: '加载中...',
    };
  },
  created() {
    this.queryCurrencys();
  },
  methods: {
    // 查询金额
    async queryCurrencys() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let ret = await this.$Api(queryCurrencys, {
          type: '2',
        });
        if (ret && ret.code === 200) {
          this.amountList = ret.data.list;
          if (!this.amountList.length) {
            this.dataText = '暂无充值金额';
          }
          this.changItem();
          this.dcInfo = JSON.parse(JSON.stringify(ret.data.daichong));
        } else if (ret.tip) {
          Toast(ret.tip);
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('查询失败，请稍后再试');
      }
    },
    // 选择金额
    selectAmount(index) {
      this.selectIndex = index;
      this.payMethods = [...this.amountList[index].rchgType];
      this.changItem();
    },
    // 选择金币
    changItem() {
      let payData = {
        rechargeList: [...this.amountList[this.selectIndex].rchgType], // 支付方式列表
        dcInfo: this.dcInfo, // 代充信息
        popType: 'gold', // 弹窗类型
        productType: undefined, // 商品类型
        productID: this.amountList[this.selectIndex].id, // 商品ID
        dcType: 0, // 代充商品类型 0-购买金币 1-是会员 2-是果币  3-是裸聊
        buyType: 2, // 商品类型  1-是金币 2-是游戏币 3-是果币 4-是商品
        discountedPrice: this.amountList[this.selectIndex].money / 100, // 充值金额
      };
      this.$refs.rechargeBox.changItem(payData);
    },
    showPay(flag) {
      this.isRecharge = flag;
    },
  },
  beforeRouteEnter(to, from, next) {
    //视频跳转过来
    if (from.name == 'CommunityVideo' || from.name == 'HorizontalVideo' || from.name == 'ShortVideo') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = vm.$store.getters.currentVideoObj['id'];
        vm.$refs.rechargeBox.graphicsTitle = vm.$store.getters.currentVideoObj['title'];
      });
    } else if (from.name == 'ComRecommend' || from.name == 'Announcement') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'banner';
      });
    } else if (from.name == 'TaskHall') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'signIn';
      });
    } else if (from.name == 'PostDetails') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'exceptional';
      });
    } else {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'user';
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.myWallet {
  height: 100%;
  background-color: #fff;
  .header {
    height: 44px;
    padding: 0 14px;
    .back {
      > svg {
        height: 19px;
        width: 19px;
      }
    }
    .title {
      color: rgb(0, 0, 0);
      font-size: 14px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      font-weight: 600;
    }
    .right-btn {
      color: rgb(253, 139, 58);
      font-size: 12px;
      font-weight: 500;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 14px;
    .walletBox {
      height: 133px;
      width: 335px;
      background: url('./../../../assets/png/walletBg.png') no-repeat;
      background-size: 100% 100%;
      margin: 10px auto 0;
      .titleBox {
        display: flex;
        align-items: center;
        padding: 10px 13px;
        .goldIcon {
          height: 32px;
          width: 35px;
          background: url('./../../../assets/png/gold.png') no-repeat;
          background-size: 100% 100%;
          margin-right: 4px;
        }
        span {
          font-size: 18px;
          font-weight: 600;
          color: rgb(113, 44, 20);
        }
      }
      .amountBox {
        font-size: 44px;
        color: #fff;
        text-align: center;
        line-height: 44px;
        span {
          font-size: 20px;
        }
      }
    }
    .subTitle {
      font-size: 16px;
      font-weight: 600;
      color: rgb(51, 51, 51);
      margin: 33px 0 0px;
    }
    .subTip {
      font-size: 12px;
      color: rgb(102, 102, 102);
      line-height: 20px;
      margin-bottom: 12px;
      span {
        color: rgb(247, 78, 130);
      }
    }
    .subTip1 {
      font-size: 12px;
      color: rgb(102, 102, 102);
    }
    .goldCoinMain {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 10px;
      .goldCoinBox {
        height: 80px;
        width: 110px;
        border-radius: 10px;
        background-color: rgb(244, 240, 245);
        padding: 3px 6px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .rmb {
          font-size: 13px;
          span {
            font-size: 10px;
          }
        }
        .goldCoin {
          font-size: 18px;
          font-weight: 800;
          margin: 2px 0;
          .goldCoinIcon {
            height: 29px;
            width: 29px;
            background: url('./../../../assets/png/gold.png') no-repeat;
            background-size: 100% 100%;
          }
        }
        .gift {
          font-size: 10px;
          color: #fff;
          border-radius: 6px 0 10px 0;
          padding: 0 4px;
          background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
          position: absolute;
          left: 0;
          top: 0;
          z-index: 999;
        }
      }
      .goldCoinBoxActive {
        border: 2.5px solid rgb(255, 103, 143);
        .rmb,
        .goldCoin {
          color: rgb(255, 103, 143);
        }
        .gift {
          color: #fff;
          background-color: rgb(255, 103, 143);
          left: -2px;
          top: -2px;
        }
      }
    }
    .nodata {
      font-size: 20px;
      text-align: center;
      margin-top: 40px;
    }
    .sizeBox {
      height: 70px;
    }
    .openNow {
      position: fixed;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 271px;
      height: 49px;
      background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      border-radius: 8px;
      text-align: center;
      line-height: 49px;
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
